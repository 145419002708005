let utils = {}

/**
 * 毫秒数时间格式化
 * @param {*} seconds
 */
utils.dateFormatShort = function (seconds) {
  let oDate = new Date(seconds)
  let oYear = oDate.getFullYear()
  let oMonth = oDate.getMonth() + 1
  let oDay = oDate.getDate()
  let oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) // 最后拼接时间
  return oTime
}

utils.dateFormat = function (seconds) {
  let oDate = new Date(seconds)
  let oYear = oDate.getFullYear()
  let oMonth = oDate.getMonth() + 1
  let oDay = oDate.getDate()
  let oHour = oDate.getHours()
  let oMin = oDate.getMinutes()
  let omins = oDate.getMilliseconds()
  let oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) + ' ' + getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(omins)
  return oTime
}

/**
 * 倒计时 hh:mm:ss
 * @param {秒数} e
 */
utils.countDown = function (e) {
  let day = Math.floor(e / 3600 / 24)
  let hour = Math.floor(e % 86400 / 3600)
  hour += day * 24
  let minite = Math.floor(e % 86400 % 3600 / 60)
  let second = Math.floor(e % 86400 % 3600 % 60)
  return `${getzf(hour)}:${getzf(minite)}:${getzf(second)}`
}

function getzf (num) {
  if (parseInt(num) < 10) {
    num = '0' + num
  }
  return num
}

utils.cartSortArr = function (arr, str) {
  let _arr = []
  let _t = []
  // 临时的变量
  let _tmp

  // 按照特定的参数将数组排序将具有相同值得排在一起
  arr = arr.sort(function (a, b) {
    let s = a['cartSku'][str]
    let t = b['cartSku'][str]

    return s < t ? -1 : 1
  })

  if (arr.length) {
    _tmp = arr[0]['cartSku'][str]
  }
  // 将相同类别的对象添加到统一个数组
  for (let i in arr) {
    if (arr[i]['cartSku'][str] === _tmp) {
      _t.push(arr[i])
    } else {
      _tmp = arr[i]['cartSku'][str]
      _arr.push(_t)
      _t = [arr[i]]
    }
  }
  // 将最后的内容推出新数组
  _arr.push(_t)
  return _arr
}

utils.arrayIndexOf = (list, val, key) => {
  let index = -1
  list.forEach((item, i) => {
    let str = item[key]
    if (str === val) {
      index = i
    }
  })
  return index
}

utils.isPC = () => {
  let userAgentInfo = navigator.userAgent;
  let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

export default utils
