import request from '@/libs/request'

export const findAllNews = (data) => request({
  url: `news/list`,
  method: 'get',
  data
})

export const findNewsById = (id) => request({
  url: `news/${id}`,
  method: 'get'
})


