<template>
  <div class="home">
    <my-header/>
    <section class="carousel">
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(img, i) in state.images" :key="i">
          <img lazy-load :src="img"/>
        </van-swipe-item>
      </van-swipe>
    </section>
    <section class="brand-introduce">
      <div class="title">革新科技</div>
      <div class="title">源启细胞智能</div>
      <div class="icon">
        <img :src="require('@/assets/img/icon/1.png')" />
      </div>
      <div class="content">ceruru是由</div>
      <div class="content">拉脱维亚语ceru【希望】和日语uruou【湿润】</div>
      <div class="content">两种语言相结合创造出的词语</div>
      <div class="content">b是英语beauty（美丽）的首字母</div>
      <div class="content">充分体现了ceruru.b投身于美丽事业的信念</div>
      <div class="content">希望通过持续的创新，不断的进取</div>
      <div class="content">让美丽与希望，永远传承</div>
    </section>
    <news :news-list="state.newsList"/>
    <series :product-list="state.productList"/>
    <section class="advantage">
      <div class="advantage-title">Ceruru.b 三大优势</div>
      <div class="advantage-item" v-for="(advantage, i) in state.advantageList" :key="i">
        <div class="advantage-img">
          <img lazy-load :src="advantage.img" alt="产品图">
        </div>
        <div class="advantage-name">{{advantage.name}}</div>
        <div class="advantage-content">{{advantage.content}}</div>
      </div>
    </section>
    <section class="brand-brief">
      <div class="brand-brief_head">
        <div class="brand-brief_logo">
          <img :src="require('@/assets/img/icon/5.png')" alt="logo">
        </div>
        <div class="brand-brief_title">品牌简介</div>
      </div>
      <div class="brand-brief_content">
        <div>
          Ceruru.b创立于2016年，是日本株式会社美黛旗下高端美容院线品牌，专注为每位爱美者提供精准肌肤解决方案，由社长羽鸟聪经过多年的研究创立于日本国东京都新宿。Ceruru.b现有系列产品涵盖了抗衰面膜、泡沫面膜、化妆水、水霜、高护面霜、科技眼霜、洗面奶等可以满足不同护肤需求,多种肤质护理。
        </div>
        <div>
          Ceruru.b不懈追求对美的塑造，致力于高科技研发，挖掘天然、安全的新技术与新原料通过高新科技与天然植物相结合致力于为每位美者提供精准肌肤解决方案。
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import MyHeader from '@/components/header/Header';
import News from '@/components/news/News';
import Series from '@/components/series/Series';
import { findProductListByBrandId } from '@/api/product';
import { findAllNews } from '@/api/news';
import { Swipe, SwipeItem, Lazyload } from 'vant';
export default {
  name: 'Home',
  components: {
    MyHeader,
    News,
    Series,
    [Lazyload.name]: Lazyload,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  setup() {
    const state = reactive({
      productList: [],
      newsList: [],
      images: [
        require('@/assets/img/carousel/1.jpg'),
        require('@/assets/img/carousel/2.jpg'),
        require('@/assets/img/carousel/3.jpg'),
        require('@/assets/img/carousel/4.jpg'),
        require('@/assets/img/carousel/5.jpg')
      ],
      advantageList: [
        {
          name: '1、研发优势',
          img: require('@/assets/img/series/4.jpg'),
          content: 'Ceruru.b依托以羽鸟聪为核心的专业研发团队，多年来在专业技术领域进行基础研究和应用开发，目前公司拥有专业的研发团队，涵盖分子生物学、细胞生物学、皮肤健康等技术领域进行功效机制研究。研发团队开发出众多明星产品，形成技术配方储备，满足市场及消费者的需求。'
        },
        {
          name: '2、生产优势',
          img: require('@/assets/img/series/5.jpg'),
          content: 'Ceruru.拥有国际自动化生产设备从10万级无尘净化，达到GMPC微菌化标准的生产车间，Ceruru.b致力于生产全过程的安全保证。'
        },
        {
          name: '3、品控优势',
          img: require('@/assets/img/series/6.jpg'),
          content: 'Ceruru.b在源头的精准把控让产品达到高品质要求，而且，Ceruru.b坚持六个“不含”，不含防腐剂、不含合成香料、不含色素、不含矿物油、不含硅不含紫外线吸收剂,追求有机天然。'
        }
      ]
    });

    const getProductList = async () => {
      const { data } = await findProductListByBrandId('34322d5c4c4c4a73a8bd65051b47d999');
      state.productList.splice(0, state.productList.length, ...data.content);
    }

    const getNewsList = async () => {
      const { data } = await findAllNews({
        size: 20,
        page: 0
      });
      state.newsList.splice(0, state.newsList.length, ...data.content);
    }

    onMounted(() => {
      getProductList();
      getNewsList();
    });

    return {
      state
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  .carousel {
    img {
      width: 100%;
      height: 695px;
    }
  }

  .brand-introduce {
    text-align: center;
    margin: 10px 0 46px;

    .title {
      font-size: 24px;
      font-weight: 500;
      color: #080B0E;
      line-height: 30px;
    }
    
    .icon {
      margin: 18px auto 28px;
      width: 106px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      font-size: 16px;
      color: #010101;
      line-height: 30px;
    }

  }

  .advantage {
    margin: 30px 0 44px;
    padding: 0 29px;

    .advantage-title {
      margin-bottom: 20px;
      height: 24px;
      font-size: 24px;
      color: #080B0E;
      line-height: 24px;
      text-align: center;
    }

    .advantage-item {
      margin-bottom: 35px;

      .advantage-img {
        width: 316px;
        height: 283px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }
      }

      .advantage-name {
        padding: 0 23px;
        margin: 32px 0 18px;
        font-size: 24px;
        font-weight: 500;
        color: #D40D82;
        line-height: 24px;
      }

      .advantage-content {
        padding: 0 23px;
        font-size: 16px;
        color: #000000;
        line-height: 28px;
        text-align: justify;
      }
    }
  }

  .brand-brief {
    padding: 42px 16px;
    background: #d40e82;
    color: #FFFFFF;

    .brand-brief_head {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 46px;

      .brand-brief_logo, img {
        width: 151px;
        height: 36px;
      }

      .brand-brief_title {
        margin-left: 18px;
        padding-left: 22px;
        height: 30px;
        line-height: 30px;
        font-size: 24px;
        font-weight: 500;
        border-left: 2px solid #FFFFFF;
      }
    }

    .brand-brief_content {
      font-size: 16px;
      
      div {
        line-height: 30px;
        text-align: justify;
      }
    }
  }

}
</style>