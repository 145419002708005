<template>
  <div class="news">
    <div class="name">NEWS</div>
    <div class="content">
      <div class="news-item" v-for="(news, i) in newsList" :key="i" @click="handleToPage(news)">
        <div class="news-date">{{news.publishTime}}</div>
        <div class="news-title">{{news.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: 'news',
  props: ['news-list'],
  setup() {

    const router = useRouter();

    const handleToPage = (data) => {
      const { id, status } = data;
      if (status === 0) return; 
      router.push({
        name: 'news_detail',
        params: {
          id
        }
      })
    }
    
    return {
      handleToPage
    }
  },
};
</script>

<style lang="less" scoped>
.news {
  padding: 15px 16px;
  background: #D40D82;

  .name {
    height: 24px;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 30px;
  }

  .content {
    height: 200px;
    margin-top: 16px;
    padding: 0 15px;
    border-radius: 2px;
    background: #FFFFFF;
    text-align: left;
    overflow-y: auto;

    .news-item {
      margin-top: 28px;
      border-bottom: 1px solid #969696;

      &:last-of-type {
        margin-bottom: 12px;
      }

      .news-date {
        font-size: 16px;
        color: #D40D82;
        line-height: 18px;
      }

      .news-title {
        margin: 12px 0 10px;
        font-size: 16px;
        font-weight: 500;
        color: #414141;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
