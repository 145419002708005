<template>
  <header>
    <div class="logo" @click="handleToPage('/')">
      <img :src="require('@/assets/img/icon/logo.png')" alt="logo" />
    </div>
    <div class="about" @click="handleToPage('/')">
      <div class="avatar">
        <img :src="require('@/assets/img/icon/4.png')" alt="用户头像" />
      </div>
      <div class="text">
        <span>关于我们</span>
      </div>
    </div>
  </header>
</template>

<script>

import { useRouter } from 'vue-router';

export default {
  name: 'my-header',
  setup() {
    const router = useRouter();
    const handleToPage = (path) => {
      router.push({ path });
    };

    return {
      handleToPage,
    };
  },
};
</script>

<style lang="less" scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 18px;
  background: #d40d82;

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 78px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .about {
    display: flex;
    align-items: center;
    height: 18px;
    font-size: 14px;
    color: #fefefe;

    .avatar {
      width: 17px;
      height: 17px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      margin-left: 6px;
    }
  }
}
</style>
