/** *************** 部署环境 *************** */
const requestApi = 'https://www.kxlist.com/api/' // pro
const ossApi = 'https://www.kxlist.com/zuul/api/oss/'
const oss = 'https://oss.kxlist.com/'

/** *************** 开发环境 *************** */
const devRequestApi = 'https://www.kxlist.com/api/'

const api = process.env.NODE_ENV === 'production' ? requestApi : devRequestApi

export {
  api,
  oss,
  ossApi
}
