import { api, oss } from '@/libs/publicPath.js'
const app = {
  state () {
    return {
      api,
      oss
    }
  },
  mutations: {
  },
  actions: {}
}

export default app
