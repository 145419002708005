import 'amfe-flexible'
import { createApp } from 'vue'
import utils from '@/libs/utils'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'


router.beforeEach((to, from, next) => {
  if(utils.isPC()) {

    const { protocol, host } = location;
    let page = to.name;
    if (page === 'home') {
      page = 'index';
    }
    page += '.html';
    let id = '';
    if (to.params.id || to.query.id) {
      id = to.params.id || to.query.id;
    }
    let url = protocol + '//www.' + host.substr(2) + "/" + page;
    if (id) {
      url += `?id=${id}`;
    }
    console.log(url);
    location.href = url;
  }

  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
});

createApp(App).use(store).use(router).mount('#app')
