// 默认利用axios的cancelToken进行防重复提交。
// 如需允许多个提交同时发出。则需要在请求配置config中增加 neverCancel 属性，并设置为true

import axios from 'axios'
import { api } from '@/libs/publicPath'
// import store from '../store/index'
// import { Toast } from 'vant'

/* 创建axios实例 */
const service = axios.create({
  baseURL: api,
  withCredentials: true,
  timeout: 5000 // 请求超时时间
})

/* request拦截器 */
service.interceptors.request.use((config) => {
  // 在这里可以统一修改请求头，例如 加入 用户 token 等操作
  //   if (store.getters.sessionId) {
  //     config.headers['X-SessionId'] = getSessionId() // 让每个请求携带token--['X-Token']为自定义key
  //   }
  return config
}, (error) => {
  Promise.reject(error)
})

/* respone拦截器 */
service.interceptors.response.use(
  (response) => {
    // 移除队列中的该请求，注意这时候没有传第二个参数f
    // removePending(response.config)
    if (response.data.code !== 0) {
      // Message.error(response.data.msg)
    }
    return response.data
  },
  (error) => {
    // 异常处理
    let message = ''
    let errorInfo = error.response
    if (!errorInfo) {
      const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
      errorInfo = {
        statusText,
        status,
        request: { responseURL: config.url }
      }
      message = status + '：' + errorInfo.statusText
    } else {
      if (errorInfo.data.code === -1) {
        message = errorInfo.data.msg
        // Toast.fail(message)
        sessionStorage.clear()
        window.location.reload()
        return Promise.reject(message)
      } else if (errorInfo.data.code === 1) {
        message = errorInfo.data.msg
      } else {
        message = errorInfo.data.status + '：' + errorInfo.data.message
      }
    }
    // Toast.fail(message)
    return Promise.reject(message)
  }
)

export default service
