<template>
  <div class="series-tab">
    <div :class="state.currentIndex === i ? 'tab-item active' : 'tab-item'" v-for="(tab, i) in state.tabList" :key="i">
      <span @click="handleTabClick(i, tab.text)">{{tab.text}}</span>
      <div class="series-swiper" v-show="state.currentIndex === i">
        <img :src="require('@/assets/img/icon/2.png')" alt="滑块">
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
export default {
  name: 'series-tab',
  // props: ['tab-list'],
  props: {
    tabList: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['toggle-tab'],
  setup(props, context) {
    const state = reactive({
      tabList: props.tabList,
      currentIndex: 0
    });

    const handleTabClick = (index, name) => {
      state.currentIndex = index;
      context.emit('toggle-tab', { index, name });
    }

    return {
      state,
      handleTabClick
    }
  },
};
</script>

<style lang="less" scoped>
.series-tab {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 56px;

  &::before {
    content: " ";
    position: absolute;
    top: -22px;
    width: 100%;
    height: 1px;
    background: #080B0E;
  }

  .tab-item {
    position: relative;
    padding: 0 25px;
    height: 18px;
    font-size: 18px;
    color: #080B0E;

    .series-swiper {
      position: absolute;
      top: -34px;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &:nth-of-type(2) {
      position: relative;
      // border-left: 1px solid #080B0E;
      // border-right: 1px solid #080B0E;

      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 3px;
        width: 1px;
        height: 12px;
        background: #080B0E;
      }
      &::after {
        content: " ";
        position: absolute;
        right: 0;
        top: 3px;
        width: 1px;
        height: 12px;
        background: #080B0E;
      }
    }

    &.active {
      font-weight: 500;
      color: #080B0E;
    }
  }
}
</style>
