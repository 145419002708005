<template>
  <div class="series">
    <div class="series-title">Ceruru.b 三大系列</div>
    <series-tab :tab-list="tabList" @toggle-tab="handleToggleTab"/>
    <div class="series-img">
      <img lazy-load :src="state.series.img" />
    </div>
    <div class="series-desc" v-if="state.series.title">
      <div class="series-desc-content">{{state.series.title}}</div>
      <div class="series-desc-img">
        <img :src="require('@/assets/img/icon/3.png')" alt="">
      </div>
    </div>
    <div class="series-product"  v-if="state.seriesProductList.length !== 0">
      <div class="series-product-list" :style="{ width: (state.seriesProductList.length * 180) + 'px'}">
        <template v-if="state.seriesProductList.length !== 0">
          <div class="series-product-item" v-for="(product, i) in state.seriesProductList" :key="i">
            <div class="product-img">
              <img :src="state.oss + product.commodityImage" alt="商品图片">
            </div>
            <div class="product-name">{{product.name + ' ' + product.specification}}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from 'vue';
import { useStore } from 'vuex'
import SeriesTab from './SeriesTab';
import { Lazyload } from 'vant';
export default {
  components: {
    SeriesTab,
    [Lazyload.name]: Lazyload
  },
  props: {
    productList: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const store = useStore();
    
    const seriesList = [
      {
        title: '无惧时间  不留痕迹',
        img: require('@/assets/img/series/3.jpg')
      },
      {
        title: '水润保湿  满满水活力',
        img: require('@/assets/img/series/2.jpg')
      },
      {
        title: '',
        img: require('@/assets/img/series/1.jpg')
      }
    ];

    const state = reactive({
      oss: store.state.app.oss,
      seriesProductList: [],
      series: seriesList[0]
    });

    const tabList = [{ id: 1, text: '源系列' }, { id: 2, text: '润系列' }, { id: 3, text: '经典系列' }]

    let productList = reactive(props.productList);

    watch(props.productList, () => {
      productList = props.productList;
      state.seriesProductList = productFilter(tabList[0].text);
    });

    const productFilter = (field) =>{
      return productList.filter(item => {
        return item.series === field
      })
    }

    const handleToggleTab = ({ index, name }) => {
      state.series = Object.assign({}, seriesList[index]);
      state.seriesProductList = productFilter(name);
    }

    return {
      state,
      tabList,
      handleToggleTab
    }
  },
};
</script>

<style lang="less" scoped>
.series {
  margin: 66px 0 70px;
  text-align: center;

  .series-img {
    margin-top: 16px;
    height: 179px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .series-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 75px;
    background: #D40D82;
    text-align: center;
    overflow: hidden;

    .series-desc-content {
      margin: 20px 0 2px;
      line-height: 20px;
      font-size: 20px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .series-desc-img {
      display: flex;
      margin-top: 10px;
      width: 17px;
      height: 11px;
      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  .series-product {
    padding: 16px;
    overflow-x: auto;

    .series-product-list {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .series-product-item {
        width: 160px;
        margin-right: 20px;

        .product-img {
          width: 160px;
          height: 160px;;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .product-name {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 500;
          color: #42474B;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    
  }
}
</style>
